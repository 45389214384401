import { useContext } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import AuthContext from "../contexts/auth";
import { Answers } from "../pages/Answers";
import { Collection } from "../pages/Collection";
import { Ddreader } from "../pages/Ddreader";
import { Contact } from "../pages/Contact";
import { HelpLibraries } from "../pages/HelpLibraries";

import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { BookInfo } from "../pages/BookInfo";
import { EditEnterprise } from "../pages/EditEnterprise";
import { EditUser } from "../pages/EditUser";
import { Home } from '../pages/Home';
import { InternalNews } from "../pages/InternalNews";
import { MyDorinateca } from "../pages/MyDorinateca";
import { News } from "../pages/News";
import { Notifications } from "../pages/Notifications";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import { Projects } from "../pages/Projects";
import { Recources } from "../pages/Recources";
import { Register } from "../pages/Register";
import { RegisterEnterprise } from "../pages/RegisterEnterprise";
import { Search } from "../pages/Search";
import UseTerms from "../pages/UseTerms";
import { GlobalStyles } from "../styles/global";
import { ModalStyle } from "../styles/modal";



function Router() {
    const context = useContext(AuthContext);
    const {
        themeContrast,
        themeLetterSpacing,
        themeFontFamily,
        themeFontSize,
        isLogged,
        user
    } = context
    const isEnterprise = (user.cnpj ?? "").length > 0
    const whiteBlackTheme = {
        textColor: "#000000",
        backgroundColor: "#FFFFFF",
        shadowColor: "#000000",
        bodyBackground: "#FAF8F2"
    }

    const yellowBlackTheme = {
        textColor: "#000000",
        backgroundColor: "#E9B00D",
        shadowColor: "#000000",
        bodyBackground: "hsl(44, 89%, 78%)"
    }

    const blackWhiteTheme = {
        textColor: "#FAF8F2",
        backgroundColor: "#000000",
        shadowColor: "#FAF8F2",
        bodyBackground: "#222222"
    }

    const blackYellowTheme = {
        textColor: "#E9B00D",
        backgroundColor: "#000000",
        shadowColor: "#FAF8F2",
        bodyBackground: "#222222"
    }

    const availableThemes = [whiteBlackTheme, yellowBlackTheme, blackWhiteTheme, blackYellowTheme]

    const targetColorTheme = availableThemes[themeContrast - 1]

    const possibleFonts = ['Source Sans Pro', `'Roboto Slab', serif`, 'OpenDyslexic']

    const targetFontTheme = possibleFonts[themeFontFamily - 1]

    const availableFontSizeIncrement = [0, 6, 12]

    const targetFontSize = availableFontSizeIncrement[themeFontSize - 1]

    const theme = {
        ...targetColorTheme,
        letterSpacing: themeLetterSpacing - 1,
        fontFamily: targetFontTheme,
        fontSize: targetFontSize,
        highlight: "#DF4263"
    }

    return (<ThemeProvider theme={theme}>
        <h1 className="ds-none">Dorinateca</h1>
        <GlobalStyles />
        <ModalStyle />
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/acervo" exact component={Collection} />
                <Route path="/ddreader" exact component={Ddreader} />
                <Route path="/noticias" exact component={News} />
                <Route path="/noticias/ler/:id" exact component={InternalNews} />
                <Route path="/projetos-e-patrocinios" exact component={Projects} />
                <Route path="/perguntas-frequentes" exact component={Answers} />
                <Route path="/recursos-de-acessibilidade" exact component={Recources} />
                <Route path="/bibliotecas-parceiras" exact component={HelpLibraries} />
                <Route path="/contato" exact component={Contact} />
                <Route path="/buscar" exact component={Search} />
                <Route path="/detalhes-da-obra/:id" exact component={BookInfo} />
                <Route path="/politica-privacidade" exact component={PrivacyPolicy} />
                <Route path="/termos-de-uso" exact component={UseTerms} />
                {
                    !isLogged ?
                        <>
                            <Route path="/cadastro" exact component={Register} />
                            <Route path="/cadastro-empresa" exact component={RegisterEnterprise} />
                        </>
                        : null
                }
                {
                    isLogged ? <>
                        <Route path="/editar-cadastro" exact component={isEnterprise ? EditEnterprise : EditUser} />
                        <Route path="/minha-dorinateca" exact component={MyDorinateca} />
                        <Route path="/alertas" exact component={Notifications} />
                    </>
                        : null
                }
                <Route path="" component={() => <Redirect to="/" />} />
            </Switch>
        </BrowserRouter>
    </ThemeProvider>
    );
}

export default Router;
